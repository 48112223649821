  .journey-map{
    overflow-x:scroll;
    white-space: nowrap;
    overflow-y:auto;
  }

 
@media (max-height: 1080px) { 
    .journey-map{
        height: 845px;
      }
 }

 
@media (max-height: 768px) { 
    .journey-map{
        height: 500px;
      }
 }

  .cjm-stages__content {
    display: inline-flex;
    align-items: center;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: min-content;
    padding-right: 10px;
  }

  .row {
    display: inline-flex; 
    margin-left: 40px;
  }

  .row .stage {
    width: 250px;
    margin-right: 10px;
  }

  .stage-histogram{
    width: 65px!important;
  }
  
  .stage-header{
    background-color: #6FDA44;
    padding: 12px;
    /* color: white; */
    font-weight: 500;
    justify-content: space-between;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
  }


  .truncate{
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
  }

  .grand-average-container{
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 4px;
    border-color: #1d4354;
    border-style: solid;
    border-width: 1px;
    width: 70%;
    margin: 0 auto;
  }

  .grand-average-value{
    padding: 5px;
    font-size: 1.4rem;

  }

  .grand-average-caption{
    background-color: #6fda44;
    padding: 3px;
  }